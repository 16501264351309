export const STR_LEADS_ARRAY = "leadsArray";
export const SELLER_NUMBER = "SELLER_NUMBER";

// URL PARAMS START
export const URL_CONSTANTS = {
  PageUniqueID: "PageUniqueID",
  SELLER_USER_ID: "SELLER_USER_ID",
  SUPERVISOR_PHONE: "SUPERVISOR_PHONE",
  USR_TYP: "USR_TYP",
  LeadID: "LeadID",
  SELLER_PINCODE: "SELLER_PINCODE",
  CustomerName: "CustomerName",
  SellerName: "SellerName",
};
// URL PARAMS START

export const LANGUAGE = "LANGUAGE";
export const ImagePath = "ImagePath";
export const UniqueSelfieID = "UniqueSelfieID";
