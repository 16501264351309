import React from "react";
import { Route, useLocation } from "react-router-dom";
import loadable from "@loadable/component";
import { AnimatedSwitch } from "react-router-transition";

// PAGES
import Homepage from "./pages/Home";

const SelfiePage = loadable(() => import("./pages/Selfie"));
const DataPage = loadable(() => import("./pages/Data"));
const AVPage = loadable(() => import("./pages/AV"));
const Leads = loadable(() => import("./pages/Leads"));
const AddLeads = loadable(() => import("./pages/AddLeads"));
const EstimatedIncome = loadable(() => import("./pages/EstimatedIncome"));
const International = loadable(() => import("./pages/international"));

export default function Navigation() {
  let location = useLocation();

  return (
    <AnimatedSwitch
      atEnter={{ opacity: 0 }}
      atLeave={{ opacity: 0 }}
      atActive={{ opacity: 1 }}
      className="switch-wrapper"
    >
      <Route path="/" exact>
        <Homepage />
      </Route>
      <Route path="/selfie">
        <SelfiePage />
      </Route>
      <Route path="/data">
        <DataPage />
      </Route>
      <Route path="/av">
        <AVPage />
      </Route>
      <Route path="/leads">
        <Leads />
      </Route>
      <Route path="/add-leads">
        <AddLeads />
      </Route>
      <Route path="/estimated-income">
        <EstimatedIncome />
      </Route>
      <Route path="/international-convention">
        <International />
      </Route>
    </AnimatedSwitch>
  );
}
