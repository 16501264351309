import React from "react";
import {
  Flex,
  Image,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Box,
  Button,
  Divider,
  Link,
  Text,
} from "@chakra-ui/react";
import { RiWhatsappFill } from "react-icons/ri";
import { FaPhoneAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import ConnecOnWhatsapp from "../connect/ConnectOnWhatsapp";
import { URL_CONSTANTS } from "../constants/storage";

const Header = () => {
  const { t } = useTranslation();

  const number =
    localStorage.getItem(URL_CONSTANTS.SUPERVISOR_PHONE) || "9833003089";

  return (
    <Flex
      direction="row"
      background="secondary.500"
      align="center"
      justify="space-between"
      px={2}
      zIndex={1050}
    >
      <Image src="/images/logo.png" height="50px" />

      <Popover>
        <PopoverTrigger>
          <Button variant="link" _focus={{ boxShadow: "none" }}>
            <Image src="/images/whatsapp.png" height="25px" />
          </Button>
        </PopoverTrigger>
        <PopoverContent mr="2">
          <PopoverArrow />
          <PopoverBody>
            <Flex direction="column">
              <Link
                _hover={{ textDecoration: "none" }}
                isExternal
                href={`tel:${number}`}
              >
                <Flex align="center" p={1}>
                  <Box as={FaPhoneAlt} mr={2} color="primary.500" />
                  <Text fontSize="sm">
                    {" "}
                    {t("COMMON.INSURANCE")} {t("COMMON.SUPERVISOR")} : {number}
                  </Text>
                </Flex>
              </Link>
              <Divider />
              <Flex align="center" p={1} onClick={ConnecOnWhatsapp}>
                <Box as={RiWhatsappFill} mr={2} color="#48BB78" />
                <Text fontSize="sm"> Connnect On Whatsapp : {number} </Text>
              </Flex>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};

export default Header;
