import {
  URL_CONSTANTS,
  LANGUAGE,
  ImagePath,
  UniqueSelfieID,
} from "../constants/storage";

const axios = require("axios");

export const getDataFromLocal = (
  param: string,
  defaultValue: any | string = ""
) => {
  return localStorage.getItem(param)
    ? localStorage.getItem(param)
    : defaultValue;
};

const Languages = {
  en: "English",
  hi: "Hindi",
  ta: "Tamil",
  bn: "Bengali",
  te: "Telgu",
  kn: "Kannada",
  ml: "Malayalam",
  gu: "Gujurati",
};

export const LogStep = async (pageNo = "") => {
  const url =
    "https://lifelineuat.reliancelife.com/RassistServices/wsCalculate.svc/PWTTermCalc";

  const Leads: Array<any> =
    // @ts-ignore
    JSON.parse(localStorage.getItem("leadsArray")) || [];

  const EstimatedIncomeFrom = () => {
    let total = 0;

    Leads.map((lead) => {
      total += lead.first.min + lead.renewal.min;
    });

    return total.toString();
  };
  const EstimatedIncomeTO = () => {
    let total = 0;

    Leads.map((lead) => {
      total += lead.first.max + lead.renewal.max;
    });

    return total.toString();
  };
  const FirstYearIncomeFrom = () => {
    let total = 0;

    Leads.map((lead) => {
      total += lead.first.min;
    });

    return total.toString();
  };

  const FirstYearIncomeTO = () => {
    let total = 0;

    Leads.map((lead) => {
      total += lead.first.max;
    });

    return total.toString();
  };
  const SecondYearRenewalIncomeFrom = () => {
    let total = 0;

    Leads.map((lead) => {
      total += lead.renewal.min;
    });

    return total.toString();
  };
  const SecondYearRenewalIncomeTO = () => {
    let total = 0;

    Leads.map((lead) => {
      total += lead.renewal.max;
    });

    return total.toString();
  };

  let lang = () => {
    if (getDataFromLocal(LANGUAGE)) {
      //@ts-ignore
      return Languages[getDataFromLocal(LANGUAGE)];
    }

    return "en";
  };

  const Data = {
    CalculatorName: "CovidPICCalc",
    Data: [
      {
        Added_By: "70268271",
        CalculatorName: "CovidPICCalc",
        CustomerName: getDataFromLocal(URL_CONSTANTS.CustomerName),
        Customer_Mobile: "",
        Language: lang(),
        Lead_ID: getDataFromLocal(URL_CONSTANTS.LeadID),
        PageStatus: pageNo,
        PageUniqueID: getDataFromLocal(URL_CONSTANTS.PageUniqueID),
        SellerMobile: getDataFromLocal(URL_CONSTANTS.SUPERVISOR_PHONE),
        SellerName: getDataFromLocal(URL_CONSTANTS.SellerName),
        Pincode: getAnswer(URL_CONSTANTS.SELLER_PINCODE),
        Usertype: getDataFromLocal(URL_CONSTANTS.USR_TYP),
        UniqueSelfieID: getDataFromLocal(UniqueSelfieID, null),
        AVQ1: getAnswer("1"),
        AVQ2: getAnswer("2"),
        AVQ3: getAnswer("3"),
        TopAdvisorEarning: getDataFromLocal("AREAINCOME"),
        LeadCount: Leads.length.toString() ? Leads.length.toString() : "0",
        EstimatedIncomeFrom: EstimatedIncomeFrom(),
        EstimatedIncomeTO: EstimatedIncomeTO(),
        FirstYearIncomeFrom: FirstYearIncomeFrom(),
        FirstYearIncomeTO: FirstYearIncomeTO(),
        SecondYearRenewalIncomeFrom: SecondYearRenewalIncomeFrom(),
        SecondYearRenewalIncomeTO: SecondYearRenewalIncomeTO(),
        Param1: "",
        Param2: "",
        Param3: "",
        Param4: "",
      },
    ],
    Lead_ID: getDataFromLocal(URL_CONSTANTS.LeadID),
  };

  axios
    .post(url, Data, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then(function (response: any) {
      console.log(response);
    })
    .catch(function (error: any) {
      console.log(error);
    });
};

const getAnswer = (qstNo: string) => {
  return getDataFromLocal(`vans${qstNo}`)
    ? getDataFromLocal(`vans${qstNo}`) == "yes"
      ? "Y"
      : "N"
    : "";
};

export const uploadSelfie = (file: any) => {
  let newFile: any;

  if (typeof file === "string") {
    newFile = b64toBlob(file);
  } else {
    newFile = file;
  }

  const url =
    "https://lifelineuat.reliancelife.com/RassistServices/wsCalculate.svc/SaveSelfieIncomePartner";

  let data = new FormData();
  // @ts-ignore
  data.append("pageuniqueid", getDataFromLocal(URL_CONSTANTS.PageUniqueID));
  // @ts-ignore
  data.append("lead_id", getDataFromLocal(URL_CONSTANTS.LeadID));
  // @ts-ignore
  data.append("added_by", "90437636");
  data.append("Image", newFile);

  axios
    .post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(function (response: any) {
      if (response.ImagePath) {
        localStorage.setItem(ImagePath, response.ImagePath);
      }

      if (response.UniqueSelfieID) {
        localStorage.setItem(UniqueSelfieID, response.UniqueSelfieID);
      }
    })
    .catch(function (error: any) {
      console.log(error);
    });
};

function b64toBlob(b64Data: string) {
  let contentType = b64Data.split(";")[0].split(":")[1] || "";
  let sliceSize = 512;
  let realData = b64Data.split(",")[1].split(":")[1] || "";

  var byteCharacters = atob(realData);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export const AddLeadAPI = (data: any) => {
  const url =
    "https://lifelineuat.reliancelife.com/RassistServices/wsCalculate.svc/SaveLeadIncomePartner";

  axios
    .post(url, data)
    .then(function (response: any) {
      console.log(response);
    })
    .catch(function (error: any) {
      console.log(error);
    });
};
