import { SELLER_NUMBER } from "../constants/storage";
import { URL_CONSTANTS } from "../constants/storage";

const ConnecOnWhatsapp = () => {
  const WHATSAPP_NO =
    localStorage.getItem(URL_CONSTANTS.SUPERVISOR_PHONE) || "9833003089";

  let msg = `
 Dear  ${
   localStorage.getItem(URL_CONSTANTS.SellerName)
     ? localStorage.getItem(URL_CONSTANTS.SellerName)
     : "Insurance Supervisor"
 }, I would like to contact you and discuss my leads/prospects with you. Looking forward to meeting you. Thank you
 `;

  let url = `https://wa.me/${WHATSAPP_NO}?text=${encodeURI(msg)}`;

  window.open(url, (Math.random() * 10000).toString());
};

export default ConnecOnWhatsapp;
