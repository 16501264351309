import React, { useEffect } from "react";
import Container from "../components/container";
import {
  Flex,
  Text,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Image,
  Button,
  useDisclosure,
  useClipboard,
} from "@chakra-ui/react";
import map from "lodash/map";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import { isChrome } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { URL_CONSTANTS, LANGUAGE } from "../constants/storage";
import { LogStep } from "../services/api";

const getParamsFromURL = () => {
  let url = window.location.href;

  url = window.location.href.split("__").join("&");
  url = url.split("?").join("?&");

  let urlParams = new URLSearchParams(url);

  for (const [key, value] of Object.entries(URL_CONSTANTS)) {
    const myParam = urlParams.get(value);

    if (myParam) localStorage.setItem(value, myParam ? myParam : "");
  }
};

export default function Home() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { hasCopied, onCopy } = useClipboard(window.location.href);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    getParamsFromURL();

    if (!isChrome) {
      onOpen();
    }

    setTimeout(() => {
      LogStep("0");
    }, 3000);
  }, []);

  return (
    <Container
      classes="homepage"
      style={{ backgroundImage: "url(/images/virus.png)" }}
    >
      <br />

      <HeroBanner />

      <Hashtag />

      <LanguageSelection />

      <br />
      <Text fontSize="xs" color="gray" textAlign="center">
        Strictly for internal communication only
      </Text>
      <br />
      <br />

      <Footer>
        <Box mx="auto">
          <Link to="/selfie">
            <Button size="sm" colorScheme="secondary" mx="auto">
              {t("COMMON.NEXT")}
            </Button>
          </Link>
        </Box>
      </Footer>

      <Modal isOpen={isOpen} isCentered onClose={onClose}>
        <ModalOverlay />
        <ModalContent m={4}>
          <ModalHeader>
            <Text fontSize="sm"> Recommendation </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text color="gray.500" fontSize="sm">
              For the best user experience, we recommend that you open this
              link/app inside the chrome browser.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              <Text fontSize="xs" color="gray.500">
                {" "}
                Continue Anyway{" "}
              </Text>
            </Button>
            <Button colorScheme="blue" ml="auto" onClick={onCopy}>
              <Text fontSize="sm">
                {hasCopied ? "Link Copied" : "Copy Link"}{" "}
              </Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
}

const HeroBanner = () => {
  const { t, i18n } = useTranslation();
  return (
    <Flex
      background="primary.500"
      color="white"
      direction="row"
      borderRadius="6px"
      padding={4}
      justifyContent="space-between"
      align="center"
      mb={4}
      zIndex={50}
    >
      <Flex direction="column" fontSize="md">
        <Text> {t("HOME_PAGE.WELCOME")} </Text>
        <Text fontWeight="bold">
          {localStorage.getItem(URL_CONSTANTS.CustomerName)
            ? localStorage.getItem(URL_CONSTANTS.CustomerName)
            : "Partner"}
        </Text>
      </Flex>

      <Image src="/images/family.png" height="80px" />
    </Flex>
  );
};

const Hashtag = () => {
  const { t, i18n } = useTranslation();
  return (
    <Flex direction="column" my={4} zIndex={50}>
      <div className="hashtag">
        <div className="hashtagBadge">
          <Text color="primary.500"> # {t("COMMON.HASHTAG")} </Text>
        </div>
      </div>
      <Flex
        direction="row"
        background="secondary.100"
        borderRadius="4px"
        p={4}
        marginTop={"-16px"}
        pt={6}
      >
        <Box as={FaQuoteLeft} />
        <Text px={2} textAlign="center" fontSize="sm" w="100%">
          {t("HOME_PAGE.QUOTE")}
        </Text>
        <Box as={FaQuoteRight} />
      </Flex>
    </Flex>
  );
};

const LanguageSelection = () => {
  const { t, i18n } = useTranslation();

  let lang = () => {
    if (typeof localStorage.getItem("LANGUAGE") === "string")
      return localStorage.getItem("LANGUAGE");

    return "en";
  };

  const [activeLang, setActiveLang] = React.useState(lang);

  const languages = [
    {
      title: "English",
      fl: "E",
      sf: "en",
      background: "linear-gradient(60deg,#00b4db,#0083b0)",
    },
    {
      title: "हिन्दी",
      fl: "ह",
      sf: "hin",
      background: "linear-gradient(60deg ,#f85032,#e73827)",
    },
    {
      title: "தமிழ்",
      fl: "த",
      sf: "ta",
      background: "linear-gradient(60deg,#f7971e,#ffd200)",
    },
    {
      title: "বাংলা",
      fl: "ব",
      sf: "bn",
      background: "linear-gradient(60deg,#66bb6a,#43a047)",
    },
    {
      title: "తెలుగు",
      fl: "తె",
      sf: "te",
      background: "linear-gradient(60deg,#ff758c,#ff7eb3)",
    },
    {
      title: "ಕನ್ನಡ",
      fl: "ಕ",
      sf: "kn",
      background: "linear-gradient(60deg,#96deda,#50c9c3)",
    },
    {
      title: "മലയാള",
      fl: " മ ",
      sf: "ml",
      background: "linear-gradient(60deg,#11998e,#38ef7d)",
    },
    {
      title: "ગુજરાત",
      fl: "ગુ",
      sf: "gu",
      background: "linear-gradient(60deg,#ff512f,#f09819)",
    },
  ];

  const changeLanguage = (lang: string) => {
    setActiveLang(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem("LANGUAGE", lang);
    LogStep("0");
  };

  return (
    <Flex direction="column" zIndex={50}>
      <Text color="primary.500" my={2} mb={4} textAlign="center">
        {t("HOME_PAGE.SELECT_LANG")}
      </Text>

      <Flex direction="row" wrap="wrap" align="center" justify="center">
        {map(languages, (lang, index) => (
          <Flex
            direction="column"
            pr={1}
            w="33%"
            justifyContent="center"
            align="center"
            mb="4"
            onClick={() => changeLanguage(lang.sf)}
            key={index}
          >
            <Flex
              height={activeLang === lang.sf ? "50px" : "40px"}
              width={activeLang === lang.sf ? "50px" : "40px"}
              borderRadius="50%"
              align="center"
              justify="center"
              mb={1}
              style={{ backgroundImage: lang.background }}
            >
              <Text
                fontSize={activeLang === lang.sf ? "22px" : "16px"}
                color="white"
                fontWeight="bold"
              >
                {lang.fl}
              </Text>
            </Flex>
            <Text
              color={activeLang === lang.sf ? "secondary.500" : "#222"}
              fontWeight={activeLang === lang.sf ? "bold" : "400"}
            >
              {lang.title}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
