import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";
import Layout from "./components/layout";
import Header from "./components/header";
import Theme from "./theme";
import { BrowserRouter as Router } from "react-router-dom";
import Navigation from "./Navigation";
import { useTranslation } from "react-i18next";
import { Suspense } from "react";

function App() {
  const { t, i18n } = useTranslation();

  return (
    <Suspense fallback={<div>Loading... </div>}>
      <ChakraProvider theme={Theme}>
        <Router>
          <Layout>
            <Header />
            <Navigation />
          </Layout>
        </Router>
      </ChakraProvider>
    </Suspense>
  );
}

export default App;
