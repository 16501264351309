import React from "react";
import { Flex } from "@chakra-ui/react";

export default function Layout(props: any) {
  return (
    <Flex direction="column" width="100%" maxWidth="600px" mx="auto">
      {props.children}
    </Flex>
  );
}
