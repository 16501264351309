import { Flex } from "@chakra-ui/layout";

export default function Footer(props: any) {
  return (
    <Flex
      className="footer-bg"
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
      }}
      width="100%"
      px={4}
      py={2}
      zIndex={50}
    >
      {props.children}
    </Flex>
  );
}
