import React from "react";
import { Flex } from "@chakra-ui/layout";

export default function Container(props: any) {
  return (
    <Flex
      direction="column"
      p={4}
      className={`pageContainer ${props.classes}`}
      style={{ ...props.style, width: "100%" }}
      position="relative"
      background="gray.50"
    >
      {props.children}
    </Flex>
  );
}
